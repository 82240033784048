import React from 'react';
import {
  CodeSnippet,
  IconDataTableCode,
  PlatformTab,
  Section,
} from '../../../../components';

const AndroidTab = ({ data }) => {
  return (
    <PlatformTab>
      <CodeSnippet
        scope={{}}
        code={`<ImageView
  android:layout_width="wrap_content"
  android:layout_height="wrap_content"
  android:src="@drawable/user_add"
/>`}
        platform="android"
        gitHubLink="Icons"
        disableCodeEditing
      />

      <Section title="Icons">
        <IconDataTableCode data={data} platform="android" />
      </Section>
    </PlatformTab>
  );
};

export default AndroidTab;
